// src/components/LoginPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../css/Login.css';
import '../css/Util.css';
import { backendUrl } from '../App';
import PanelFooterComponent from './extras/PanelFooterComponent';
import { popupErrorWarn } from '../FrontendUtils';

const LoginPage: React.FC<{ setIsLoggedIn: (loggedIn: boolean) => void, isLoggedIn: boolean }> = ({ setIsLoggedIn, isLoggedIn }) => {
  document.title = "Painel Titan | Fazer Login";
  const navigate = useNavigate();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const GeneralInfoToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
  });

  if (sessionStorage.getItem('justloguedout') != null) {
    sessionStorage.removeItem('justloguedout');
    GeneralInfoToast.fire({
      icon: 'info',
      title: 'Deslogado com sucesso.',
      timer: 1000 * 2,
      timerProgressBar: true
    });
  }

  useEffect(() => {
    // Navega para o dashboard quando `isLoggedIn` for true
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (!username || !password) {
      GeneralInfoToast.fire({
        icon: 'error',
        title: 'Preencha todos os campos.',
        timer: 1000 * 2,
        timerProgressBar: true
      });
      return;
    }

    setButtonDisabled(true);
    GeneralInfoToast.fire({
      title: 'Fazendo login...',
      didOpen: () => {
        GeneralInfoToast.showLoading();
      }
    });
    loginUser(username, password);
  };

  const loginUser = async (username: string, password: string) => {
    try {
      const apiLoginResult = await axios.post(`${backendUrl}/auth/login`, {
        user: username,
        password: password
      });
      const apiLoginResultData = apiLoginResult.data;

      if (!apiLoginResultData.success) {
        switch (apiLoginResultData.zabbixErrorCode) {
          case -32602:
          case -32500:
            GeneralInfoToast.fire({
              icon: 'error',
              title: apiLoginResultData.error,
              timer: 1000 * 2,
              timerProgressBar: true
            });
            setButtonDisabled(false);
            return;
          default:
            popupErrorWarn('Um erro ocorreu na API Titan ao fazer login.', apiLoginResultData.error);
            setButtonDisabled(false);
            return;
        }
      }

      sessionStorage.setItem('session_user_info', apiLoginResultData.data);
      sessionStorage.setItem('session_user', apiLoginResultData.data['username']);
      sessionStorage.setItem('session_user_is_zabbix_admin', apiLoginResultData.data['isZabbixAdmin']);
      sessionStorage.setItem('session_token', apiLoginResultData.data['sessionid']);
      sessionStorage.setItem('session_user_SID', apiLoginResultData.data['SID']);
      document.cookie = `connect.sid=${apiLoginResultData.data['SID']}; path=/; expires=${new Date(new Date().getTime() + Number.MAX_VALUE).toUTCString()}`;
      if (rememberMe) {
        localStorage.setItem('session_user_info', apiLoginResultData.data);
        localStorage.setItem('session_user', apiLoginResultData.data['username']);
        localStorage.setItem('session_user_is_zabbix_admin', apiLoginResultData.data['isZabbixAdmin']);
        localStorage.setItem('session_token', apiLoginResultData.data['sessionid']);
        localStorage.setItem('session_user_SID', apiLoginResultData.data['SID']);
      } else {
        localStorage.removeItem('session_user_info');
        localStorage.removeItem('session_user');
        localStorage.removeItem('session_user_is_zabbix_admin');
        localStorage.removeItem('session_token');
        localStorage.removeItem('session_user_SID');
      }
      console.log("Logado com sucesso, indo para o dashboard...");
      setIsLoggedIn(true);
    } catch (err) {
      popupErrorWarn('Um erro ocorreu ao fazer login. A API Titan parece estar indisponível no momento.', err);
      setButtonDisabled(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      handleLogin();
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="logo-container">
          <img src="logo_migrati.png" alt="Logo MigraTI" className="logo" />
        </div>
        <div className="wrap-login100">
          <span className="login100-form-title-1">Painel Titan</span>

          <form className="login100-form validate-form">
            <div className="wrap-input100 validate-input m-b-26" data-validate="O usuário é obrigatório">
              <span className="label-input100"><i className="fas fa-user"></i> Usuário</span>
              <input
                className="input100"
                type="text"
                name="username"
                value={username}
                onChange={handleUsernameChange}
                onKeyPress={handleKeyPress}
                disabled={buttonDisabled}
                placeholder="Usuário do Titan"
              />
              <span className="focus-input100"></span>
            </div>

            <div className="wrap-input100 validate-input m-b-18" data-validate="A senha é obrigatória">
              <span className="label-input100"><i className="fas fa-key"></i> Senha</span>
              <input
                className="input100"
                type="password"
                name="pass"
                value={password}
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
                disabled={buttonDisabled}
                placeholder="Senha do Titan"
              />
              <span className="focus-input100"></span>
            </div>

            <div className="remember-me-container" style={{ paddingLeft: '1%' }}>
              <label htmlFor="remember-me" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id="remember-me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  disabled={buttonDisabled}
                  style={{ marginRight: '5px' }}
                />
                <a>Continuar logado</a>
              </label>
            </div> 

            <div className="login100-form-btn-container">
              <button className="login100-form-btn" type="button" onClick={handleLogin} disabled={buttonDisabled}>
                {buttonDisabled ? 'Aguarde...' : 'Fazer login'}
              </button>
            </div>

          </form>
        </div>
      </div>
      <PanelFooterComponent />
    </div>
  );
};

export default LoginPage;

