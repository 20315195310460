// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: lightgray;
}

.loading-custom-swal {
    z-index: 9999;
    padding-top: 70px;
}

.loading-custom-swal-fullscreen {
    z-index: 9999;
}

.selected-server-title {
    display: inline;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: gray;
    height: 100%;
    width: 100%;
    text-align: center;
}

/* Empty layout */
.empty-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: gray;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 5%; 
}`, "",{"version":3,"sources":["webpack://./src/css/Templates.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA,iBAAiB;AACjB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":["body {\n    background-color: lightgray;\n}\n\n.loading-custom-swal {\n    z-index: 9999;\n    padding-top: 70px;\n}\n\n.loading-custom-swal-fullscreen {\n    z-index: 9999;\n}\n\n.selected-server-title {\n    display: inline;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    color: gray;\n    height: 100%;\n    width: 100%;\n    text-align: center;\n}\n\n/* Empty layout */\n.empty-layout {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    color: gray;\n    height: 100%;\n    width: 100%;\n    text-align: center;\n    padding-top: 5%; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
