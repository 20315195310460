// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-100px);
}`, "",{"version":3,"sources":["webpack://./src/css/EditUserModal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,6BAA6B;IAC7B,iCAAiC;AACrC;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,6BAA6B;AACjC","sourcesContent":[".ReactModal__Overlay {\n    opacity: 0;\n    transform: translateY(-100px);\n    transition: all 500ms ease-in-out;\n}\n\n.ReactModal__Overlay--after-open {\n    opacity: 1;\n    transform: translateY(0px);\n}\n\n.ReactModal__Overlay--before-close {\n    opacity: 0;\n    transform: translateY(-100px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
